import revive_payload_client_6DOLv55whk from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_idb-keyval@6.2.1_ior_e9da76a25af6fcff1c6e0ad1040517f0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_a4yvSKAXuc from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_idb-keyval@6.2.1_ior_e9da76a25af6fcff1c6e0ad1040517f0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9hKfm6ypfO from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_idb-keyval@6.2.1_ior_e9da76a25af6fcff1c6e0ad1040517f0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5nUZvoJJs1 from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_idb-keyval@6.2.1_ior_e9da76a25af6fcff1c6e0ad1040517f0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_0GAMK48BFe from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_idb-keyval@6.2.1_ior_e9da76a25af6fcff1c6e0ad1040517f0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_xKuoAGNCZL from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_idb-keyval@6.2.1_ior_e9da76a25af6fcff1c6e0ad1040517f0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Oyaxpco7qn from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_idb-keyval@6.2.1_ior_e9da76a25af6fcff1c6e0ad1040517f0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/robustastudio/mazaya/karaca/.nuxt/components.plugin.mjs";
import prefetch_client_NaBSbhUEFb from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_eslint@8.57.0_idb-keyval@6.2.1_ior_e9da76a25af6fcff1c6e0ad1040517f0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_HRJ6y7G7xR from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.2.4_magicast@0.3.4_rollup@4.19.1_vite_bf6f26026ccf6549e6c5b9e4df330f13/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_7Dq8dv0Auu from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.2.4_magicast@0.3.4_rollup@4.19.1_vite_bf6f26026ccf6549e6c5b9e4df330f13/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_nYJ6s6st68 from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt-delay-hydration@1.3.5_magicast@0.3.4_rollup@4.19.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_vJMX8vIrJ6 from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt-jsonld@2.0.8_typescript@5.5.4/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import gtm_client_tIpX3Cfo6b from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/@robustastudio+e-commerce@1.43.0-1.42.0-branching.1_magicast@0.3.4_rollup@4.19.1_vite@5_9cd0902146acefa867b7f201b273d029/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/gtm.client.mjs";
import ssr_caching_dQb8wq4e23 from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/@robustastudio+e-commerce@1.43.0-1.42.0-branching.1_magicast@0.3.4_rollup@4.19.1_vite@5_9cd0902146acefa867b7f201b273d029/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/ssr-caching.mjs";
import i18n_D08jHecU1j from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_magicast@0.3.4_rollup@4.19.1_vue@3.4.34_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import loading_GxQ2Z9Goxa from "/builds/robustastudio/mazaya/karaca/plugins/loading.ts";
import purify_html_Hz9hnAad5c from "/builds/robustastudio/mazaya/karaca/plugins/purify-html.ts";
import setup_0lDNjxjaWh from "/builds/robustastudio/mazaya/karaca/plugins/setup.ts";
export default [
  revive_payload_client_6DOLv55whk,
  unhead_a4yvSKAXuc,
  router_9hKfm6ypfO,
  payload_client_5nUZvoJJs1,
  navigation_repaint_client_0GAMK48BFe,
  check_outdated_build_client_xKuoAGNCZL,
  chunk_reload_client_Oyaxpco7qn,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NaBSbhUEFb,
  pwa_icons_HRJ6y7G7xR,
  pwa_client_7Dq8dv0Auu,
  nuxt_plugin_nYJ6s6st68,
  plugin_vJMX8vIrJ6,
  gtm_client_tIpX3Cfo6b,
  ssr_caching_dQb8wq4e23,
  i18n_D08jHecU1j,
  loading_GxQ2Z9Goxa,
  purify_html_Hz9hnAad5c,
  setup_0lDNjxjaWh
]